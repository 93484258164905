import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import BannerLanding from "../components/BannerLanding";
import TimelineMecosud from "../components/TimelineMecosud";
import picBookHeart from "../assets/images/book-heart.jpg";
import picLoek from "../assets/images/photo-loek.jpeg";
import picRob from "../assets/images/photo-rob.png";


const VzcPage = (props) => (
  <Layout>
    <Helmet>
      <title>Over Ons | Mecosud B.V.</title>
      <meta name="description" content="Generic Page" />
    </Helmet>

    <BannerLanding
      title="Over Ons"
      subTitle="Mecosud B.V."
      styleClass=""
      photo={picBookHeart}
    />

    <div id="main" className="alt" style={{ backgroundColor: "" }}>
    <section id="three" className="spotlights">
        <section>
            <img src={picRob} alt="" className="image" />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Robbert van Zuiden</h3>
              </header>
              <p>
                Robbert heeft een uitgebreide ervaring met het ontwikkelen van
                digitale producten voor de gezondheidszorg, start-ups in
                educatie en marketing, en heeft een sterk oog voor het creëren van
                gebruiksvriendelijke producten.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Loek van Zuiden</h3>
              </header>
              <p>
                Sinds 1983 is oprichter Loek actief als uitgever in de
                gezondheidszorg. Hij heeft een succesvolle reeks aan producten
                zoals richtlijnen, boeken en kranten gelanceerd.
              </p>
            </div>
          </div>
          <img src={picLoek} alt="Loek van Zuiden" className="image" />
        </section>
      </section>
      <section id="two">
        <div className="inner">
          <header>
            <h2>Onze Tijdlijn</h2>
          </header>
          <TimelineMecosud />
        </div>
      </section>
     
    </div>
  </Layout>
);

export default VzcPage;
