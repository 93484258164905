import React from "react";
import { Link } from "gatsby";
import { Timeline, TimelineEvent } from "react-event-timeline";

import LogoVZC from "../assets/images/logo-vzc_square.png";
import LogoMedidact from "../assets/images/logo-medidact.jpeg";
import ImgMM from "../assets/images/img-mm.png";

const titleStyle = {
  fontSize: "20px",
  fontWeight: "500",
  display: "inline",
};

const subtitleStyle = {
  fontSize: "16px",
  fontWeight: "500",
  display: "inline",
  marginLeft: "10px",
};

const iconStyle = {
  borderColor: "white",
  position: "relative",
  left: "-2px",
};

const iconImageStyle = {
  width: "30px",
  height: "30px",
  objectFit: "cover",
  borderRadius: "1000px",
};

const TimelineMecosud = (props) => (
  <Timeline>
    <TimelineEvent
      title="Diverse uitgeefprojecten"
      titleStyle={titleStyle}
      subtitle="1983 - 1991"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      icon={<img src={LogoMedidact} style={iconImageStyle} />}
      iconStyle={iconStyle}
      contentStyle={{
        backgroundColor: "rgba(255,255,255,0)",
        boxShadow: "none",
      }}
      collapsible={true}
    >
      <p>Onze werkzaamheden in de gezondheidszorg begonnen met o.a. <em>de Medicus</em>, een wekelijkse krant voor de huisarts. Daarnaast was er <em>MDTV,</em> een innovatieve videoservice voor medici via een netwerk van Video 2000 banden. Via deze service werden nascholings- en andere programma's verzorgt. Ook was er <em>De papieren visite</em>, een tweewekelijkse uitgave met patienten-casuistiek in samenwerking met Reed Healthcare.</p>
    </TimelineEvent>
    {/* <TimelineEvent
      title="De Medicus"
      titleStyle={titleStyle}
      subtitle="1983"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >
      <p>Een wekelijkse krant voor de gezondheidszorg voor de huisarts.</p>
    </TimelineEvent>
    <TimelineEvent
      title="MDTV"
      titleStyle={titleStyle}
      subtitle="1983"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >
      <p></p>
    </TimelineEvent>
    <TimelineEvent
      title="De papieren visite / klinische visite"
      titleStyle={titleStyle}
      subtitle="1983"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >
      <p>Tweewekelijkse uitgave met patienten-casuistiek in samenwerking met Reed Healthcare.</p>
    </TimelineEvent> */}
    <TimelineEvent
      title="Van Zuiden Communications"
      titleStyle={titleStyle}
      subtitle="1991 - Present"
      subtitleStyle={subtitleStyle}
      icon={<img src={LogoVZC} style={iconImageStyle} />}
      iconStyle={iconStyle}
      contentStyle={{
        backgroundColor: "rgba(255,255,255,0)",
        boxShadow: "none",
      }}
      collapsible={false}
    >
      <p>
        In 1991 werd <em>Van Zuiden Communications</em> als zelfstandige uitgeverij
        opgericht.
      </p>
      <p>
      Op 24 april 2018 heeft <em>Van Zuiden Communications B.V.</em> de Medidact tak
        van het bedrijf aan Springer Healthcare overgedragen (<a href="https://springerhealthcare.nl/over-ons/persbericht-springer-nature-neemt-medidact-onderdeel-van-van-zuiden-communications/" target="_blank">Persbericht</a>).  
        Onder de handelsnaam
        van Zuiden Communications blijft Mecosud B.V. zich bezig houden met het
        uitgeven van zakboekjes, boeken, apps en educatieve projecten gericht op
        de gezondheidszorg.
      </p>
    </TimelineEvent>
    <TimelineEvent
      title="App- & website-ontwikkeling"
      titleStyle={titleStyle}
      subtitle="2009 - Present"
      subtitleStyle={subtitleStyle}
      icon={<img src={LogoVZC} style={iconImageStyle} />}
      iconStyle={iconStyle}
      contentStyle={{
        backgroundColor: "rgba(255,255,255,0)",
        boxShadow: "none",
      }}
      collapsible={true}
    >
      <p>
        Sinds 2009 houden wij ons bezig met het ontwikkelen van (native) apps, websites en web-apps.Een bekende app was <em>het Acute Boekje</em>, beschikbaar voor Google Android en Apple iOS. Ook hebben wij verschillende producten voor derden gemaakt, onder andere voor <a href="https://www.amc.nl">AMC/UvA</a>, <a href="https://www.uva.nl">UvA</a> & <a href="https://www.eurocommercial.com">Eurocommercial Properties N.V.</a>.
      </p>
      <p>Robbert heeft diverse ontwerp- en ontwikkelwerkzaamheden (UI/UX) gedaan voor verschillende start-ups, zoals <a href="https://www.ihatestatistics.com/" target="_blank">I Hate Statistics</a>, <a href="https://www.tweetbeam.com/" target="_blank">TweetBeam</a> en <a href="https://www.relive.cc">Relive</a>.</p>
    </TimelineEvent>
    {/* <TimelineEvent
      title="Modern medicine"
      titleStyle={titleStyle}
      subtitle="1998"
      subtitleStyle={subtitleStyle}
      icon={<img src={ImgMM} style={iconImageStyle} />}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >
      Specialistenblad voor de huisarts. Modern Medicine was een maandelijkse uitgave in het Nederlands.
    </TimelineEvent>
    <TimelineEvent
      title="Recept farmacotherapie"
      titleStyle={titleStyle}
      subtitle="1994"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >
      Farmacotherapie, voorschrijven van geneesmiddelen.
    </TimelineEvent>
    <TimelineEvent
      title="NATEC nieuws bulletin"
      titleStyle={titleStyle}
      subtitle="2001"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >
      3 x per jaar hét HIV/AIDS nieuws. De eerste uitgaves op dit gebied, in NL. Voor specialisten.
    </TimelineEvent>
    <TimelineEvent
      title="CBO richtlijnen: evidence based"
      titleStyle={titleStyle}
      subtitle="2002"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >
      CBO richtlijn, boekjes
    </TimelineEvent>
    <TimelineEvent
      title="Wetenschappelijke bladen voor de beroepsverenigingen"
      titleStyle={titleStyle}
      subtitle="diverse"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >
      KNO, NIV, NVALT, N t v microbiologie
    </TimelineEvent>
    <TimelineEvent
      title="Zakboekjes Acute"
      titleStyle={titleStyle}
      subtitle="diverse"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >
      Introductie van de bekende 'zakboekjes', informatie in een handzaam formaat in de doktersjas, Acute Boekje interne geneeskunde, Acute Longziekten, Acute Neurologie, 
    </TimelineEvent>
    <TimelineEvent
      title="Custom uitgeefprojecten industrie, verenigingen"
      titleStyle={titleStyle}
      subtitle="diverse"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >

    </TimelineEvent> */}
    {/* <TimelineEvent
      title="Medidact"
      titleStyle={titleStyle}
      subtitle="diverse"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      icon={<img src={LogoMedidact} style={iconImageStyle} />}
      iconStyle={iconStyle}
      contentStyle={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "none" }}
      collapsible={true}
    >
      ?
    </TimelineEvent> */}
    <TimelineEvent
      title="inFlow interactive flowcharts"
      titleStyle={titleStyle}
      subtitle="2020 - Present"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      icon={<img src={LogoMedidact} style={iconImageStyle} />}
      iconStyle={iconStyle}
      contentStyle={{
        backgroundColor: "rgba(255,255,255,0)",
        boxShadow: "none",
      }}
      collapsible={false}
    >
      <p>Sinds 2020 bieden wij onze expertise m.b.t. het gebruik van beslisbomen aan via onze
      dienst{" "}
      <Link to="/inflow-interactive-flowcharts">
        inFlow interactive flowcharts
      </Link></p>
    </TimelineEvent>
    {/* <TimelineEvent
      title="Muziekproductie & custom sounddesign"
      titleStyle={titleStyle}
      subtitle="2020 - Present"
      subtitleStyle={subtitleStyle}
      icon={<i className="icon alt fa-linkedin"></i>}
      icon={<img src={LogoMedidact} style={iconImageStyle} />}
      iconStyle={iconStyle}
      contentStyle={{
        backgroundColor: "rgba(255,255,255,0)",
        boxShadow: "none",
      }}
      collapsible={true}
    >
      <p>Sinds 2020 bieden wij onze expertise m.b.t. het gebruik van beslisbomen aan via onze
      dienst{" "}
      <Link to="/inflow-interactive-flowcharts">
        inFlow interactive flowcharts
      </Link></p>
    </TimelineEvent> */}
  </Timeline>
);

export default TimelineMecosud;
